import { Icon, UserMenu, useMobile, useSidenav } from '@octano/global-ui';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import { useUserState } from '../../hooks/useUserState';
import styles from './TopBar.module.scss';

interface Props {
  titleKey: string;
}

const TopBar = ({ titleKey }: Props) => {
  const { t } = useTranslation();
  const translatedTitle = t(`views.${titleKey}`, {
    ns: 'layouts.authorized',
  });

  const history = useHistory();
  const isMobile = useMobile();

  const { toggle: toggleSidenav } = useSidenav();
  const { resetUserState, userData } = useUserState();

  const openManual = () => {
    window.open('/tenant/Manual Docente Campus.pdf', '_blank')?.focus();
  };

  const logOut = () => {
    resetUserState();
    history.push('/');
  };

  return (
    <div className="g-tabs-header">
      <div
        className={clsx({ [styles.bar]: true, [styles.barMobile]: isMobile })}
      >
        <Container fluid>
          <Row className="align-items-center justify-content-between">
            {isMobile && (
              <Col xs="auto">
                <span onClick={() => toggleSidenav()}>
                  <Icon name="menu" size="30px" className={styles.menuIcon} />
                </span>
              </Col>
            )}
            {!isMobile && (
              <Col>
                <span className={styles.title}>{translatedTitle}</span>
              </Col>
            )}
            <Col xs="auto">
              <UserMenu
                fullName={userData?.fullname || ''}
                email={userData?.email || ''}
                menuItems={[
                  {
                    text: 'Manual de Usuario',
                    onClick: openManual,
                  },
                  {
                    icon: 'log_out',
                    text: 'Cerrar sesión',
                    onClick: logOut,
                  },
                ]}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile && (
        <Container fluid>
          <h1 className={styles.title}>{translatedTitle}</h1>
        </Container>
      )}
    </div>
  );
};

export default TopBar;
