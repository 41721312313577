import { SidenavProps } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../locales/translations';

// TODO: Los textos de los menús deberían venir desde las traducciones
export function useSidenavLinks(): SidenavProps['links'] {
  const { t } = useTranslation(TranslationsKeys.AUTHORIZED_LAYOUT);
  return [
    {
      path: '/classes/start',
      name: t('sidenav.classesStart'),
      icon: 'chalkboard',
    },
    {
      path: '/classes/history',
      name: t('sidenav.classesHistory'),
      icon: 'classes_history',
    },
    {
      path: '/classes/untracked-attendance',
      name: t('sidenav.classesUntrackedAttendance'),
      icon: 'class_untracked_attendance',
    },
    {
      path: '/classes/tracking',
      name: t('sidenav.classesTracking'),
      icon: 'attendance_tracking',
    },
    {
      path: '/grade-entry/list',
      name: t('sidenav.gradeEntryList'),
      icon: 'grade_entry',
    },
    {
      path: '/reports',
      name: t('sidenav.reports'),
      icon: 'certificates_requests',
    },
    {
      path: '/period-end',
      name: t('sidenav.periodEnd'),
      icon: 'schedule_upload',
    },
  ];
}
